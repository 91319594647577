import React from "react"
import styled from "styled-components"
import {Container, Row, Col} from "react-bootstrap"
import {Title, Section, Text} from "../../components/Core"

const BigTitle = styled(Title)`{
  font-size: 3.5em;
}`;

const Content = (props) => {
    return (
        <>
            <Section className="pb-0">
                <Container>
                    <Row className="justify-content-center pb-4">
                        <Col lg="6">
                            <BigTitle>
                                {props.pageData.section_subtitle}
                            </BigTitle>
                        </Col>
                        <Col lg="6" className="pl-lg-5">
                            <Text>
                                {props.pageData.section_text_1}
                            </Text>
                        </Col>
                        <Col lg="12" className="pt-5 mt-5 text-center">
                            <BigTitle>
                                {props.pageData.job_section_title}
                            </BigTitle>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    )
}

export default Content;
